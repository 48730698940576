import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import styled from "styled-components";
import { TextSection } from "../../Shared/Layout/components/TextSection/TextSection";

export interface OrientedOnSolutionsProps {
  className?: string;
}

const OrientedOnSolutionsInner = (props: OrientedOnSolutionsProps) => {
  const { t } = useTranslation("translations");
  ///TODO: link services
  return (
    <TextSection
      className={props.className}
      title={t("solutionsOrientedBanner.title")}
      description={t("solutionsOrientedBanner.text")}
      buttonUrl="/sluzby/"
      button={t("solutionsOrientedBanner.button")}
    />
  );
};

export const OrientedOnSolutions = styled(OrientedOnSolutionsInner)``;
