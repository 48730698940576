import { graphql } from "gatsby";
import React from "react";
import { TopHeader } from "../components/Shared/Layout/components/TopHeader/TopHeader";
import Layout from "../components/Shared/Layout/Layout";
import { AppreciationSection } from "../components/WhyWithMe/AppreciationSection/AppreciationSection";
import { CaptainSection } from "../components/WhyWithMe/BeTheCaptainSection/CaptainSection";
import { CooperationColorfulParagraphsSection } from "../components/WhyWithMe/CooperationColorfulParagraphsSection/CooperationColorfulParagraphsSection";
import { OrientedOnSolutions } from "../components/WhyWithMe/OrientedOnSolutionsSection/OrientedOnSolutions";
import { ProcessOfCooperationSection } from "../components/WhyWithMe/ProcessOfCooperationSection/ProcessOfCooperationSection";
import { TheWayIWorkSection } from "../components/WhyWithMe/TheWayIWorkSection/TheWayIWorkSection";
import { WhyWithMeHero } from "../components/WhyWithMe/WhyWithMeHeroSection/NewWhyWithMeHero";

export const query = graphql`
  query {
    strapiWhyWithMe {
      hero {
        hook
        text
        finisher
        image {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

const WhyWithMePage = (props: any) => {
  const {
    data: { strapiWhyWithMe },
  } = props;
  return (
    <Layout>
      <TopHeader title="Prečo so mnou?" />
      <WhyWithMeHero
        image={strapiWhyWithMe.hero.image.childImageSharp.fluid}
        hook={strapiWhyWithMe.hero.hook}
        text={strapiWhyWithMe.hero.text}
        finisher={strapiWhyWithMe.hero.finisher}
      />
      {/* <WhyWithMeHeroSection /> */}
      <CaptainSection />
      <AppreciationSection />
      <CooperationColorfulParagraphsSection />
      <ProcessOfCooperationSection />
      <TheWayIWorkSection />
      <OrientedOnSolutions />
    </Layout>
  );
};

export default WhyWithMePage;
