import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import styled from "styled-components";
import { TextSection } from "../../Shared/Layout/components/TextSection/TextSection";

export interface TheWayIWorkSectionProps {
  className?: string;
}

const TheWayIWorkSectionInner = (props: TheWayIWorkSectionProps) => {
  const { t } = useTranslation("translations");

  return (
    <TextSection
      className={props.className}
      title={t("whyWithMePage.succesToMyClients.title")}
      description={t("whyWithMePage.textAboutHelping")}
    />
  );
};

export const TheWayIWorkSection = styled(TheWayIWorkSectionInner)``;
